<template>
  <v-container fluid>

    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
          placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"            
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
            <v-btn block color="amber darken-4" dark @click="dialogSave()">
                <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
            </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table
          :headers="header"
          :items="objects"
          dense
          locale="pt-BR"
          :search="search"
          :items-per-page="10"
      >  
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Dados">
            <v-btn icon small tile>
                <v-icon @click="dataSupplier(item.id)">mdi mdi-database-eye-outline</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Softwares">
            <v-btn icon small tile>
                <v-icon @click="software(item.id)">mdi mdi-desktop-classic</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <!-- MODAL -->
    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200px">
      
      <template>
        <v-form v-model="valid" ref="form1">
          <v-tabs v-model="tabs" show-arrows>
              <v-tab>Dados</v-tab>
              <v-tab>Endereço</v-tab>
              <v-tab>Anexos</v-tab>
              <v-tab>Observação</v-tab>
          </v-tabs>
          <v-divider class="my-5" />

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-row>
                <v-col md="5" sm="12" cols="12">              
                  <b> Nome Fantasia *</b>
                  <v-text-field
                    dense
                    tile
                    outlined
                    class="my-2"
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.name"
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <b> Razão Social * </b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.socialName"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                  <b> CNPJ *</b>
                  <v-text-field
                    :rules="[requiredRule, validarCNPJ, spaceRule]"
                    v-model="object.cnpj"
                    v-mask="'##.###.###/####-##'"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>            
              </v-row>

              <v-row>
                <v-col md="3" sm="12" cols="12">
                  <b> Pessoa Refencia *</b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.reference" 
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                  <b>Tipo de Ralação *</b>
                  <v-select
                    :items="relation"
                    v-model="object.typeRelation"
                    class="my-2"
                    dense
                    tile
                    outlined
                    color="primary"
                    :rules="[requiredRule, spaceRule]"
                  ></v-select>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                  <b>Telefone *</b>
                  <v-text-field
                    :rules="[requiredRule, validarTelefone]"
                    v-model="object.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>

                <v-col md="4" sm="12" cols="12">
                  <b> Email *</b>
                  <v-text-field
                    :rules="[requiredRule, validarEmail]"
                    v-model="object.email"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>                
              </v-row>   
            </v-tab-item>
          
            <v-tab-item>
              <v-row>
                <v-col md="3" sm="12" cols="12">
                  <b> Cep *</b>
                  <v-text-field
                    :rules="[requiredRule, validarCEP]"
                    v-model="object.addressZipcode"
                    @input="getCep()"
                    v-mask="'#####-###'"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="7" sm="12" cols="12">
                  <b> Endereço *</b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.addressStreet"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                  <b> Número *</b>
                  <v-text-field
                  :rules="[requiredRule, spaceRule]"
                    v-model="object.addressNumber"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>            
              </v-row>

              <v-row>
                <v-col md="4" sm="12" cols="12">
                  <b> Bairro *</b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.addressNeighborhood"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                  <b> Cidade *</b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.addressCity"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                  <b> Estado *</b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.addressState"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col> 
                <v-col md="2" sm="12" cols="12">
                  <b> Status </b>
                  <v-switch
                    v-model="object.type"
                    inset
                    :label="switchLabel()"
                  ></v-switch>
                </v-col>          
              </v-row>
            </v-tab-item>
            
            <v-tab-item>
              <v-row>
                <v-col md="12" sm="12" cols="12">
                  <v-file-input
                    chips
                    dense
                    multiple
                    class="my-2"
                    label="Anexar Documento"
                    v-model="object.docUrl"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col cols="12">
                  <b> Observações </b>
                  <v-textarea
                    outlined
                    rows="3"
                    v-model="object.observation"
                    class="my-2"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>
          
          </v-tabs-items>

          <v-divider class="my-2"></v-divider>

        </v-form>
      </template>
      
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
          <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>

    </TheDialog>
  </v-container>
</template>

<script>
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import TheToolTip from "@/components/TheToolTip"
import { mask } from "vue-the-mask"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"

export default {

  name: "Fornecedores",
  directives: { mask },
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },
  
  data: () => ({
    baseRoute: "fornecedores",
    title: "Fornecedores",
    pageIcon: "mdi-office-building-plus",

    object: {},
    objects: [],
    positions: [],
    
    dialog: false,
    search: null,
    valid: false,
    error: false,
    edit: false,
    loading: false,
    tabs: null,

    header: [
      {text: 'Codigo', value: 'id'},
      {text: 'Razão Social', value: 'socialName'},
      {text: 'Pessoa Resferência', value: 'reference'},
      {text: 'Tipo de Ralção', value: 'typeRelation'},
      { text: "Ações", value: "action", width:"14%"},
    ], 

    relation: [
      {text: 'Controlador', value: 'Controlador'},
      {text: 'Operador', value: 'Operador'},
      {text: 'Operador Conjunto', value: 'Operador Conjunto'},
    ]   
  }),

  methods: {  

    loadData() {
      this.$store.dispatch("setLoadingInfo", true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false)
          this.objects = res.data
        })
        .catch(showError)
    },

    loadPosition() {
      const url = `${baseApiUrl}/position`
      axios
        .get(url)
        .then((res) => {
          this.positions = res.data
        })
        .catch(showError)
    },

    getCep() {
      if (this.object.addressZipcode.length < 8) {
        return
      }
      const cep = this.object.addressZipcode.replace("-", "")
      const url = `${baseApiUrl}/cep/${cep}`
      this.loading = true

      axios
        .get(url)
        .then((res) => {
          this.dialog = false
          const data = res.data
          this.object.addressStreet = data.logradouro
          this.object.addressNeighborhood = data.bairro
          this.object.addressCity = data.localidade
          this.object.addressState = data.uf
          this.loading = false
          this.dialog = true
        })
        .catch(showError)
    },

    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },

    dialogUpdate(item) {
      this.object = item
      this.object.type == 1 ? this.object.type = true : this.object.type = false 
      this.edit = true
      this.dialog = true
    },

    switchLabel() {
      if (this.object.type) {
        return 'Ativo';
      } else {
        return 'Inativo';
      }
    },

    resolveForm(){
      if (!this.valid){
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text: "Os campos não foram preenchidos corretamente, você pode verificar?",
          button: "Ok!",
        })
        return
      }
      if(this.edit){
        this.update()
      } else {
        this.save()
      }
    },

    save(){
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })  
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id

      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    
    remove(id) {
      if (confirm("Remove?")) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },

    software(id){
      const route = {
          name: 'FornecedoresSoftware',
          params: { id: id}
      }
      this.$router.push(route)
    },

    dataSupplier(id){
      const route = {
          name: 'FornecedoresDados',
          params: { id: id}
      }
      this.$router.push(route)
    },
  },

  mounted() {
    this.loadData()
    this.loadPosition()
  },

}
</script>

<style>
  .full-height {
    height: 80vh;
  }
</style>